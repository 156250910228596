<template>
  <v-dialog v-model="dialog" :max-width="dialogMaxWidth" :persistent="dialogPersistent">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color"
        :large="large"
        :small="small"
        :x-small="xSmall"
        :rounded="rounded"
        :tile="tile"
        :depressed="depressed"
        :outlined="outlined"
        :style="btnStyle"
        :loading="btnLoading"
        v-bind="attrs"
        v-on="on"
      >
        {{ btnText }}
        <v-icon v-if="btnIcon" :size="iconSize" :class="{ 'ml-2': !!btnText }">
          {{ btnIcon }}
        </v-icon>
      </v-btn>
    </template>
    <v-form v-model="formIsValid" ref="form" @submit.prevent="submit" @keypress.enter="submit">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">{{ dialogDescription }}</v-col>
            <v-col>
              <v-text-field
                :type="showPassword ? 'text' : 'password'"
                label="Contraseña"
                v-model="formData.password"
                :rules="[$data.$globalRules.required]"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                counter
                autofocus
                @click:append="showPassword = !showPassword"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="grey" :disabled="loading" @click="dialog = !dialog">Cerrar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit" :loading="loading">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import apiClient from "@/services/ApiClient";

export default {
  props: {
    btnIcon: { type: String, default: "mdi-lock-open-variant-outline" },
    btnStyle: { type: String, default: "" },
    btnText: { type: String, default: "" },
    btnLoading: { type: Boolean, default: false },
    dialogDescription: { type: String, default: "Introduce tu contraseña" },
    dialogMaxWidth: { type: Number, default: 350 },
    dialogPersistent: { type: Boolean, default: false },
    dialogTitle: { type: String, default: "Identifícate para proceder" },
    iconSize: { type: Number, default: 18 },
    errorNotificationText: { type: String, default: "La contraseña no es válida" },
    errorNotificationTitle: { type: String, default: "Contraseña incorrecta" },
    large: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    xSmall: { type: Boolean, default: false },
    depressed: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    tile: { type: Boolean, default: false },
    color: { type: String, default: "" },
  },
  data: () => ({
    dialog: null,
    loading: null,
    showPassword: null,
    formData: {
      password: null,
    },
    formIsValid: null,
  }),
  methods: {
    async submit() {
      this.$refs.form.validate();

      if (this.formIsValid) {
        try {
          this.loading = true;
          await apiClient.post("/trainer/verifyAction", this.formData);
          this.dialog = false;
          this.$emit("success");
        } catch (e) {
          this.$notifyError(this.errorNotificationTitle, this.errorNotificationText + " | " + e);
          this.$emit("error");
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped></style>
